<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header headerName="巴林右旗防疫员信息统计" @selectJob="selectJob" />

    <div class="w_full p_10 flex_row_between">
      <div class="w_80_p flex_row_start">
        <div class="jian_bian_text_1 text_1 ls_2 fw_bold">
          巴林右旗{{ siteName }}防疫员总数：<span class="text_1 font_number ls_2">{{ personList.length }}</span>
        </div>

        <el-select 
          class="ml_20" 
          size="small"
          v-model="siteId"
          placeholder="选择动防站" filterable clearable 
          @change="selectSiteName"
        >
          <el-option
            v-for="(item, index) in siteList" :key="index"
            :label="item.name + '动防站'"
            :value="item.id">
          </el-option>
        </el-select>

        <el-select 
          class="ml_10" 
          v-model="userName" size="small"
          placeholder="选择防疫员" filterable clearable 
          @change="selectUserName"
        >
          <el-option
            v-for="(item, index) in personList" :key="index"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </div>

      <!-- <div class="w_20_p text_right ph_10">
        <el-button type="primary" size="small" @click="getAntiepidemicPersonList()">搜索</el-button>
        <el-button type="warning" size="small" @click="reset()">重置</el-button>
      </div> -->
    </div>

    <div class="content_box w_full h_full-110 flex_row_around ph_10 border_box">
      <!-- 1 -->
      <div class="w_full h_full p_10 border_box bgs_full chart_box_bgi_1"> 
        <div class="w_full h_full relative">
          <div class="w_full border_box flex_row_between mb_10 jian_bian_text_1 text_1 fw_bold ph_10 pb_7 border_b_1_s_ccc">
            <div class="flex_1 ">
              <span class="">头像</span>
            </div>
            <div class="flex_1 ">
              <span class="">姓名</span>
            </div>
            <div class="flex_1 ">
              <span class="">性别</span>
            </div>
            <div class="flex_1 ">
              <span class="">类型</span>
            </div>
            <div class="flex_2 ">
              <span class="">所属动防站</span>
            </div>
            <div class="flex_1 ">
              <span class="">电话</span>
            </div>
            <div class="flex_2 ">
              <span class="">负责嘎查村</span>
            </div>
          </div>

          <div class="w_full h_full-40 scroll_box overflow_y_auto">
            <div
              class="w_full h_50 lh_50 flex_row_between mb_5 color_fff text_1 ban_ma ph_10 align_center" 
              :class="{active_bgc : activeRowIndex == index}"
              v-for="(item, index) in personList" :key="index"
              @mouseover="activeRowIndex = index"
              @mouseleave="activeRowIndex = null"
            >
              <div class="flex_1"  v-if="item && item.avatar">
                <!-- 防疫员头像 -->
                <el-image
                  class=""
                  style="width: 50px; height: 50px"
                  :src="filePath + item.avatar"
                  :preview-src-list="srcList"
                  @click="handleClickImg(index)"
                />
              </div>
              <div class="flex_1 ">
                <span class="theme_color_green cursor" @click="handleClickName(item.id)">{{ item.name }}</span> 
              </div>
              <div class="flex_1">
                <span class="color_fff font_number">{{ formatDate(item.xxx) || '男' }}</span> 
              </div>
              <div class="flex_1">
                <span class="color_fff">防疫员</span>
              </div>
              <div class="flex_2 ">
                <span class="color_fff font_number">{{ item.siteName }}动防站</span> 
              </div>
              <div class="flex_1 ">
                <span class="color_fff font_number ls_2">{{ formatPhone(item.phone) }}</span> 
              </div>
              <div class="flex_2 ">
                <span class="color_fff">{{ item.remark }}</span>
              </div>
            </div>
          </div>
        </div> 
      </div> 
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'
  import geoJsonMapMixin from '@/mixins/geoJson-map.js'
  import imgMixin from '@/mixins/def-img.js'

  import { filePath } from "@/const"

  import { getAntiepidemicPersonList } from '@/api/fang-yi/fang-yi-yuan.js'
  import { getSiteList } from '@/api/base/base.js'

  export default {
    name: 'FangYiYuanList',
    mixins: [defMixin, componentsMixin, geoJsonMapMixin, imgMixin],
    data() {
      return {
        filePath: filePath,
        cascaderClearable: false,
        total: 0,
        activeRowIndex: null,
        antiepidemicPersonList: [],

        jobId: null,

        siteId: null,
        siteName: '',
        siteList: [],

        userName: null,

        srcList: [],

        personList: [],
      }
    },

    async mounted() {
      await this.getSiteList()

      if (this.siteList && this.siteList.length) {
        await this.getAntiepidemicPersonList(this.siteList[0].id, this.jobId, null)
      }
    },

    methods: {
      selectJob(jobId) {
        this.jobId = jobId;
      },

      reset() {
        this.siteName = ''

        this.getAntiepidemicPersonList(null, this.jobId, null)
      },

      // 获取 全旗 动防站点
      async getSiteList() {
        let res = await getSiteList()
        try {
          if (res.code == 1000) {
            this.siteId = res.data[0].id
            this.siteName = res.data[0].name

            let isShowMoNi = window.sessionStorage.getItem('isShowMoNi')
            if (isShowMoNi) {
              this.siteList = res.data
            } else {
              this.siteList = res.data.filter(item => item.name != '模拟防疫站')
            }
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 防疫员
      async getAntiepidemicPersonList(siteId, jobId, name) {
        const loading = this.$loading({
          lock: true,
          text: '数据加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        setTimeout(() => {
          loading.close();
        }, 1000);

        let res = await getAntiepidemicPersonList(siteId, jobId, name)
        // debugger
        try {
          if (res.code == 1000) {
            this.personList = res.data.filter(item => item.isType == 0)
            this.total = res.data.length

            this.handleClickImg(0)
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 跳转到防疫员详情页
      handleClickName(id) {
        this.goToFangYiYuanPage(id)
      },

      // 点击签到图片
      handleClickImg(index) {
        let img1 = this.filePath + this.personList[index].avatar
        let list = [img1]
        this.srcList = list
      },

      // 选择区域
      selectSiteName(siteId) {
        // debugger
        this.siteList.map(item => {
          if (item.id == siteId) {
            this.siteName = item.name
            this.getAntiepidemicPersonList(siteId, this.jobId, this.userName)
          }
        })
      },

      // 选择防疫员
      selectUserName(userName) {
        this.getAntiepidemicPersonList(this.siteId, this.jobId, userName)
      }
    }
  }
</script>

<style>
.el-pagination__total, .el-pagination__jump { color: orange !important; }

.el-image-viewer__mask { background-color: #fff !important; }
</style>
